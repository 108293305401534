<template>
  <div>
    <transition name="fade">
      <span
        :class="`w-1/5 shadow-lg p-3 text-white
        text-sm text-center font-semibold absolute ${responseBg}`"
        style="left: 77%; top: 20%"
        v-if="response != ''"
      >
        {{ response }}
      </span>
    </transition>
    <card class="p-5">
      <div class="flex justify-start items-center my-4">
        Select a level to set the data control and permissions for Employees on
        that level.
      </div>
      <template v-slot:footer>
        <CardFooter
          reloadcard
          @sortType="handleSort($event)"
          @searchResult="handleSearch($event)"
          class="mb-2 mt-2"
        />
      </template>
    </card>
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <Table
      :headers="permissionHeader"
      :items="permissionData"
      class="w-full p-5 mb-4"
      :has-number="false"
      aria-label="permission table"
      :loading="loadingData"
      :pagination-list="metaData"
      @page="handlePage($event)"
      @itemsPerPage="handleItemsPerPage($event)"
    >
      <template v-slot:item="{ item }">
        <div v-if="item.level">
          {{ item.data.level.name }}
        </div>
        <div v-else-if="item.override">
          <span v-if="item.data.override"> Yes </span>
          <span v-else> No </span>
        </div>
        <div v-else-if="item.viewableToAllAdmins">
          <span v-if="item.data.viewableToAllAdmins">
            All data accessible to all Admins
          </span>
          <span v-else> All data accessible only to select Admins </span>
        </div>
        <div
          v-else-if="item.levelId"
          class="ml-2 text-blueCrayola cursor-pointer"
          @click="
            displayModal(
              item.data.levelId,
              item.data.level.name,
              item.data.viewableToAllAdmins,
              item.data.override,
              item.data.permittedAdmins
            )
          "
        >
          <Icon
            icon-name="icon-edit"
            size="xs"
            class="mt-1"
            style="padding: 3px 10px; width: 34px; height: 34px"
          />
        </div>
      </template>
    </Table>
    <RightSideBar
      v-if="viewModal"
      @submit="savePermission"
      @close="removeModal"
      submit="Save"
      :button-class="`bg-dynamicBackBtn text-white rounded`"
      :disabled-button="disableBtn"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Permission and Data Accessibility</p>
      </template>
      <template v-slot:subtitle>
        <div class="w-full border border-dashed p-3 my-4">
          <p class="font-bold text-lg mb-3 text-carrotOrange">
            {{ levelDataName }} Data Access
          </p>
          <p class="font-semibold text-base mt-3">
            Do these settings override specific employee permission settings?
          </p>
          <div class="flex w-full mt-2">
            <radio-button
              :options="dataaccess"
              :value="levelData.override"
              col-span="col-span-12"
              class="ml-2 text-base w-full text-darkPurple"
              row-gap="gap-y-5"
              space-between="mr-2"
              v-model="levelData.override"
            />
          </div>
        </div>
      </template>
      <p class="w-full mt-4 text-base text-darkPurple font-semibold">
        Data accessible :
      </p>
      <div class="flex w-full mt-2">
        <RadioButton
          :options="accessibility"
          col-span="col-span-12"
          class="ml-2 text-base w-full text-darkPurple"
          row-gap="gap-y-5"
          space-between="mr-2"
          v-model="toAllAdmins"
        />
      </div>
      <div>
        <div v-if="viewable" />
        <div
          class="flex w-11/12 flex-col p-3 ml-4 border-l border-dashed"
          v-else
        >
          <c-select
            placeholder="--Select Admins--"
            :options="adminUsers"
            @onChange="handleAdminSelect($event)"
            variant="w-full mt-1"
            class="mr-2"
            style="width: 400px"
            v-model="adminUsers.id"
          />
          <div
            class="w-full flex mt-2 py-4"
            v-for="(admin, index) in permittedAdmins"
            :key="index"
          >
            <div class="flex flex-grow">
              <div class="w-auto flex flex-col">
                <p class="text-base font-semibold">
                  {{ `${admin.name}` }}
                </p>
              </div>
            </div>
            <div class="cursor-pointer" @click="removeAdmin(admin.id)">
              <Tag
                class="py-2 px-2 mx-2 text-desire text-sm w-1/5 h-8"
                style="
                  background-color: rgba(234, 60, 83, 0.15);
                  text-align: center;
                "
              >
                Remove
              </Tag>
            </div>
          </div>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Loader from "@/components/Loader";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import Icon from "@/components/Icon";
import RightSideBar from "@/components/RightSideBar";
import RadioButton from "@/components/RadioButton";
import Tag from "@/components/Tag";

export default {
  components: {
    Table,
    CSelect,
    Loader,
    CardFooter,
    Tag,
    RadioButton,
    Card,
    Icon,
    RightSideBar,
  },
  data() {
    return {
      disableBtn: false,
      loading: false,
      loadingData: true,
      viewModal: false,
      toAllAdmins: true,
      response: "",
      responseBg: "",
      success: false,
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      sortType: null,
      metaData: {},
      permissionHeader: [
        { title: "Job Level", value: "level" },
        { title: "Override Employee Specific Settings", value: "override" },
        { title: "Permission", value: "viewableToAllAdmins" },
        {
          title: "",
          value: "levelId",
          image: true,
          width: 8,
        },
      ],
      permissionData: [],
      dataaccess: [
        {
          name: "Yes, override all employee specific settings if any",
          radioName: "override",
          value: true,
        },
        {
          name: "No, if employee specific setting exist, leave untouched.",
          radioName: "override",
          value: false,
        },
      ],
      accessibility: [
        {
          name: "Accessible to all Admins",
          radioName: "viewableToAllAdmins",
          value: true,
        },
        {
          name: "Select Specific Admins",
          radioName: "viewableToAllAdmins",
          value: false,
        },
      ],
      levelData: {
        levelId: "",
        orgId: "",
        viewableToAllAdmins: "",
        override: "",
        permittedAdmins: [],
      },
      levelDataName: "",
      adminUsers: [],
      permittedAdmins: [],
      viewable: true,
    };
  },

  watch: {
    toAllAdmins(value) {
      if (value === "true" || value) {
        this.levelData.viewableToAllAdmins = true;
        this.viewable = true;
      }
      if (value === "false" || !value) {
        this.levelData.viewableToAllAdmins = false;
        this.viewable = false;
      }
    },
    responseBg() {
      setTimeout(() => {
        this.response = "";
      }, 7000);
    },
  },

  methods: {
    getPermissions(payload) {
      this.$_getLevelPermissions(payload).then((result) => {
        this.permissionData = result.data.datas;
        this.loadingData = false;
      });
    },
    getAdminUsers() {
      const attributes = `id,fname,lname`;
      this.$_getAdminUsers("humanar", attributes).then((result) => {
        const admins = result.data.users;
        this.metaData = result.data.meta;
        this.adminUsers = admins.map((v) => ({
          id: v.id,
          name: `${v.fname} ${v.lname}`,
        }));
      });
    },
    handleAdminSelect(value) {
      this.adminUsers.map((element) => {
        if (element.id === value) {
          this.levelData.permittedAdmins.push(value);
          const newArry = { id: `${element.id}`, name: `${element.name}` };
          this.permittedAdmins.push(newArry);
        }
        return {};
      });
    },

    removeAdmin(idnumber) {
      const filteredObj = this.permittedAdmins.filter(
        (obj) => obj.id !== idnumber
      );
      const removeAdmin = this.levelData.permittedAdmins.indexOf(idnumber);
      // remove object of id (idnumber)
      this.levelData.permittedAdmins.splice(removeAdmin, 1);
      this.permittedAdmins = filteredObj;
    },

    savePermission() {
      this.disableBtn = true;
      this.levelData.orgId = this.$orgId;
      if (this.levelData.viewableToAllAdmins) {
        this.levelData.permittedAdmins = [];
      }
      this.$_saveLevelPermissions(this.levelData)
        .then((result) => {
          this.disableBtn = false;
          this.$toasted.success(result.data.message, { duration: 5000 });
          this.removeModal();
          this.getPermissions(this.$orgId);
          this.getAdminUsers();
        })
        .catch((err) => {
          this.disableBtn = false;
          throw new Error(err);
        });
    },

    async displayModal(
      levelId,
      levelName,
      viewableToAllAdmins,
      override,
      admins
    ) {
      try {
        await this.$handlePrivilege("permissionHR", "editPermissionByJobLevel");
        this.levelDataName = levelName;
        this.levelData.levelId = levelId;
        this.levelData.viewableToAllAdmins = viewableToAllAdmins;
        this.toAllAdmins = viewableToAllAdmins;
        this.levelData.override = override;
        this.viewModal = true;
        const arrLength = admins.length;
        let i;

        for (i = 0; i < arrLength; i++) {
          this.handleAdminSelect(admins[i]);
        }

        if (viewableToAllAdmins) {
          this.viewable = true;
        } else {
          this.viewable = false;
        }
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    removeModal() {
      this.levelDataName = "";
      this.levelData.levelId = "";
      this.levelData.viewableToAllAdmins = "";
      this.levelData.override = "";
      this.permittedAdmins = [];
      this.levelData.permittedAdmins = [];
      this.toAllAdmins = "true";
      this.viewModal = false;
    },

    queryUpdate() {
      const search = this.searchWord ? `name=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      this.getPermissions(`${this.$orgId}?${search}${sort}`);
    },
    handleSort(sortType) {
      this.loadingData = true;
      this.searchWord = null;
      const changeSortType = sortType.split(":");
      let result;
      if (changeSortType[0] === "createdAt") {
        result = sortType;
      } else {
        result = `name:${changeSortType[1]}`;
      }
      this.sortType = result;
      this.queryUpdate();
    },
    handleSearch(searchWord) {
      this.loadingData = true;
      if (searchWord) {
        this.searchWord = searchWord;
        this.sortType = null;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege("permissionHR", "accessPermissionSettings");
      this.getPermissions(this.$orgId);
      this.getAdminUsers();
    } catch (error) {
      this.$toasted.error("You do not have permission to perform this task", {
        duration: 5000,
      });
    }
  },
};
</script>

<style></style>
